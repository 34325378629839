import { appConfig } from '../../commonConfig';
import { getAbsoluteUrlMinusQuery } from '../util';
import { parseErr, parseRes, APIResponse, webAPI } from './apiUtil';

type ContactUsReq = {
  name: string;
  email: string;
  message: string;
  sourceSite?: string;
  mixpanelDistinctId?: string;
  intercomDistinctId?: string;
  segmentDistinctId?: string;
};
type ContactUsRes = {};

type RequestQuoteReq = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  interestSelect: string;
  participantCount: string;
  message: string;
  sentFromPage: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  mixpanelDistinctId?: string;
  intercomDistinctId?: string;
  segmentDistinctId?: string;
  eventLabel?: string;
  page?: string;
  isEdu?: boolean;
};
type RequestQuoteRes = {};

type SubscribeToNewsletterReq = {
  email: string;
};
type SubscribeToNewsletterRes = {};

export function contactUs(data: ContactUsReq): Promise<{ success: boolean }> {
  return fetch(appConfig.apiBaseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation ContactUs($params: ContactUsParams!) {
        contactUs(
            params: $params
        )
      }    
      `,
      variables: {
        params: data,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        return {
          success: false,
        };
      } else {
        return { success: res.data.contactUs };
      }
    });
}

export const requestQuote = (data: RequestQuoteReq): Promise<{ success: boolean }> => {
  return fetch(appConfig.apiBaseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation RequestQuote($params: RequestQuoteParams!) {
        requestQuote(
            params: $params
        )
      }    
      `,
      variables: {
        params: data,
      },
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        return {
          success: false,
        };
      } else {
        return { success: res.data.requestQuote };
      }
    });
};

export function subscribeToNewsletter(data: SubscribeToNewsletterReq): APIResponse<SubscribeToNewsletterRes> {
  return webAPI
    .post('/misc/marketing-email-subscription/', {
      ...data,
      pageAbsoluteUrl: getAbsoluteUrlMinusQuery(),
    })
    .then(parseRes)
    .catch(parseErr);
}

export function getCsrfToken(): APIResponse<{ csrftoken: string }> {
  return webAPI.get('/misc/csrf-token/').then(parseRes).catch(parseErr);
}
