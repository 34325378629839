import styled from '@emotion/styled';
import {
  spacing,
  SpacingProps,
  flexbox,
  FlexboxProps,
  sizing,
  SizingProps,
  typography,
  TypographyProps,
  borders,
  BordersProps,
  display,
  DisplayProps,
  palette,
  PaletteProps,
  positions,
  PositionsProps,
} from '@material-ui/system';

interface BoxProps
  extends FlexboxProps,
    SpacingProps,
    SizingProps,
    TypographyProps,
    BordersProps,
    DisplayProps,
    PaletteProps,
    PositionsProps {}

// A utility css component based on the material ui Box component, except using emotion. The Box component in material-ui v5 will use emotion, so this can be swapped out for that
// when material-ui is upgraded to v5. Emotion allows for server side rendering with no extra config
const Box = styled.div<BoxProps>`
  ${flexbox}
  ${spacing}
    ${sizing}
    ${typography}
    ${borders}
    ${display}
    ${palette}
    ${positions}
`;

export default Box;
