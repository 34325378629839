import React from 'react';
import classnames from 'classnames';
import styles from './Grid.module.scss';

type ItemSize = 'auto' | 'flex' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Props = React.PropsWithChildren<{
  className?: string;
  container?: boolean;
  item?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 6 | 8; // spacing factor
  xs?: ItemSize;
  sm?: ItemSize;
  md?: ItemSize;
  lg?: ItemSize;
  xl?: ItemSize;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
}>;

const defaultProps: Partial<Props> = {
  spacing: 4,
};

const Grid: React.FunctionComponent<Props> = (props) => {
  const isItem = !!props.item;
  const isContainer = !!props.container || !isItem;
  const className = classnames(props.className, {
    [styles.item]: isItem,
    [styles.container]: isContainer,
    [styles[`spacing-${props.spacing}`]]: isContainer,
    [styles[`grid-xs-${props.xs}`]]: props.xs !== undefined,
    [styles[`grid-sm-${props.sm}`]]: props.sm !== undefined,
    [styles[`grid-md-${props.md}`]]: props.md !== undefined,
    [styles[`grid-lg-${props.lg}`]]: props.lg !== undefined,
    [styles[`grid-xl-${props.xl}`]]: props.xl !== undefined,
    [styles[`align-items-${props.alignItems}`]]: isContainer && props.alignItems,
    [styles[`justify-${props.justify}`]]: isContainer && props.justify,
    [styles[`wrap-${props.wrap}`]]: isContainer && props.wrap,
  });

  return <div className={className}>{props.children}</div>;
};

Grid.defaultProps = defaultProps;

export default Grid;
