import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';

import styles from './Link.module.scss';
import Icon from './icons/Icon';

export type Props = React.ComponentProps<'a'> & {
  useRouter?: boolean; // Used for fast linking between routes defined in routes.tsx using ReactRouter (won't work for any other routes)
  variant?:
    | 'default'
    | 'light'
    | 'dark'
    | 'underline'
    | 'inlineText'
    | 'btn'
    | 'btnSm'
    | 'btnSmOutline'
    | 'btnXsOutline';
  fullWidth?: boolean;
  withArrow?: boolean;
  thin?: boolean;
};

const Link = ({ className, children, variant, useRouter, fullWidth, withArrow, thin, ...otherProps }: Props) => {
  const _className = classnames(className, {
    [styles.link]: variant === 'default' || variant === undefined,
    [styles.lightLink]: variant === 'light',
    [styles.darkLink]: variant === 'dark',
    [styles.underlineLink]: variant === 'underline',
    [styles.inlineTextLink]: variant === 'inlineText',
    [styles.btnLink]: variant === 'btn',
    [styles.btnLinkSmall]: variant === 'btnSm',
    [styles.btnLinkSmallOutline]: variant === 'btnSmOutline',
    [styles.btnLinkXsOutline]: variant === 'btnXsOutline',
    [styles.fullWidth]: fullWidth,
    [styles.thin]: thin,
  });
  const arrowEl = <Icon name="arrowRight" height="1.5em" className={styles.arrow} />;
  const contentEl =
    variant === 'underline' || variant === 'inlineText' ? (
      <span className={styles.linkText}>{children}</span>
    ) : (
      children
    );

  if (useRouter) {
    return (
      <ReactRouterLink className={_className} to={otherProps.href || ''} {...(otherProps as any)}>
        {contentEl}
        {withArrow && arrowEl}
      </ReactRouterLink>
    );
  }

  return (
    <a className={_className} {...otherProps}>
      {contentEl}
      {withArrow && arrowEl}
    </a>
  );
};

export default Link;
