import React from 'react';
import classnames from 'classnames';
import styles from './Container.module.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  maxWidth?: 'responsive' | 'md' | 'lg' | 'none';
  disablePadding?: boolean;
  paddingClassName?: string;
}>;

const defaultProps: Partial<Props> = {
  maxWidth: 'responsive',
};

const Container: React.FunctionComponent<Props> = ({
  className,
  maxWidth,
  disablePadding,
  paddingClassName,
  children,
}) => (
  <div
    className={classnames(className, {
      [styles.container]: maxWidth === 'none',
      [styles[`container-${maxWidth}`]]: maxWidth !== undefined && maxWidth !== 'none',
      [paddingClassName || styles.containerPadding]: !disablePadding,
    })}
  >
    {children}
  </div>
);

Container.defaultProps = defaultProps;

export default Container;
