import React from 'react';
import classnames from 'classnames';

import styles from './Flex.module.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  flexDirection?: 'column';
  alignItems?: 'center' | 'stretch' | 'flex-start';
  justifyContent?: 'center' | 'space-between';
  wrap?: 'wrap' | 'wrap-reverse';
  gap?: string | number;
}>;

// A convenience component for common flex box use cases
const Flex = (props: Props) => (
  <div
    className={classnames(props.className, styles.flex, {
      [styles[`flexDirection-${props.flexDirection}`]]: props.flexDirection,
      [styles[`alignItems-${props.alignItems}`]]: props.alignItems,
      [styles[`justifyContent-${props.justifyContent}`]]: props.justifyContent,
      [styles[`wrap-${props.wrap}`]]: props.wrap,
    })}
    style={{
      gap: props.gap,
    }}
  >
    {props.children}
  </div>
);

export default Flex;
