import React, { useState } from 'react';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import navBarStyles from './navigation/Navbar.module.scss';
import Container from './layout/Container';
import styles from './Page.module.scss';
import Navbar from './navigation/Navbar';
import Footer from './navigation/Footer';
import { useWindowResizeDebounced } from '../hooks/utilHooks';
import { Theme } from '@material-ui/core';

export type Props = React.PropsWithChildren<{
  className?: string;
  title?: string;
  description?: string;
  noIndex?: boolean;
  hideNavbar?: boolean;
  hideFooter?: boolean;
}>;

type SectionPadding = 'none' | 'sm' | 'md' | 'lg';
type ResponsiveCssRule = {
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
};
type SectionProps = React.PropsWithChildren<{
  className?: string;
  containerProps?: React.ComponentProps<typeof Container>;
  paddingTop?: SectionPadding;
  paddingBot?: SectionPadding;
  padding?: SectionPadding; // overridden by top and bot padding if present
  omitOverflow?: boolean; // needed to allow for children to utilize position: sticky
  id?: string;
  p?: ResponsiveCssRule; // padding
}>;

const mobileNavBreakpointPx = parseInt(navBarStyles.mobileNavBreakpointStr) || 0;

const Page = (props: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Close mobile menu if window resized while open
  useWindowResizeDebounced(() => {
    if (window.innerWidth > mobileNavBreakpointPx) {
      setMobileMenuOpen(false);
    }
  });

  function onMobileMenuToggle() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <div className={props.className}>
      <Helmet>
        {props.title && <title>{props.title}</title>}
        {props.description && <meta name="description" content={props.description} />}
        {props.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>

      {!props.hideNavbar && <Navbar mobileMenuOpen={mobileMenuOpen} onMobileMenuToggle={onMobileMenuToggle} />}

      <div className={mobileMenuOpen ? styles.hide : undefined}>
        <main>{props.children}</main>
        {!props.hideFooter && <Footer />}
      </div>
    </div>
  );
};

const defaultSectionProps: Partial<SectionProps> = {
  padding: 'lg',
};
// TODO: refactor to remove paddingTop, paddingBot, padding props, and use p instead
export const Section = (props: SectionProps) => (
  <section
    className={classNames(props.className, styles.section, {
      [styles[`paddingTop-${props.paddingTop || props.padding}`]]: !!props.paddingTop || !!props.padding,
      [styles[`paddingBot-${props.paddingBot || props.padding}`]]: !!props.paddingBot || !!props.padding,
      [styles.omitOverflow]: props.omitOverflow,
    })}
    id={props.id}
    css={(theme: Theme) => ({
      padding: props.p?.xs,

      [theme.breakpoints.up('sm')]: {
        padding: props.p?.sm,
      },
      [theme.breakpoints.up('md')]: {
        padding: props.p?.md,
      },
      [theme.breakpoints.up('lg')]: {
        padding: props.p?.lg,
      },
      [theme.breakpoints.up('xl')]: {
        padding: props.p?.xl,
      },
    })}
  >
    <Container {...props.containerProps}>{props.children}</Container>
  </section>
);

Section.defaultProps = defaultSectionProps;
Page.Section = Section;
export default Page;
