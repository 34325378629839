import { createMuiTheme } from '@material-ui/core/styles';

import styles from './styles/_theme.module.scss';

// This theme object is used by material ui components and the emotion system as well, and it's needed for server rendering.
// Currently, the source of truth for the theme is the sass theme file, but this should be transitioned to here
// if we're removing sass in the future.
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: parseInt(styles.breakpointXs),
      sm: parseInt(styles.breakpointSm),
      md: parseInt(styles.breakpointMd),
      lg: parseInt(styles.breakpointLg),
      xl: parseInt(styles.breakpointXl),
    },
  },
  spacing: (factor: number) => `${factor * 0.4}rem`, // 0.4rem spacing unit
});

export default theme;
