// Determine if currently rendering on the server
export function isServer() {
  return typeof window === 'undefined' || !window.document;
}

export function debounce<T extends Function>(callback: T, ms: number) {
  let timeoutId: NodeJS.Timeout;
  let callable = (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), ms);
  };
  return <T>(<any>callable);
}

export function throttle(fn: Function, waitMs: number) {
  let isCalled = false;

  return function (...args: any) {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(function () {
        isCalled = false;
      }, waitMs);
    }
  };
}

export function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getAbsoluteUrlMinusQuery() {
  return isServer() ? '' : window.location.origin + window.location.pathname;
}
