import { RefObject, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { appConfig } from '../../commonConfig';
import { getCsrfToken } from '../apiClients/miscApiClient';
import routes, { isEduPage } from '../routes';
import { setCsrfTokenCookie } from '../services/cookieService';
import { User } from '../types';
import { debounce } from '../util';

export function useWindowResizeDebounced(handler: () => void, debounceMs = 100) {
  useEffect(() => {
    const windowResizeDebounced = debounce(handler, debounceMs);
    window.addEventListener('resize', windowResizeDebounced);

    return () => window.removeEventListener('resize', windowResizeDebounced);
  }, []);
}

export function useIsInViewport(
  ref: RefObject<Element>,
  unobserveOnEnter?: boolean,
  observerOpts?: IntersectionObserverInit
) {
  const [inViewport, setInViewport] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    const observer = new IntersectionObserver((entries, observer) => {
      if (entries.length <= 0) {
        return;
      }

      const entry = entries[0];

      setInViewport(entry.isIntersecting);

      if (entry.isIntersecting && unobserveOnEnter) {
        observer.unobserve(entry.target);
      }
    }, observerOpts);

    observer.observe(el);

    return () => observer.disconnect();
  }, [ref.current, setInViewport, unobserveOnEnter, observerOpts]);

  return inViewport;
}

// Run something when page changes (pathname only)
export function useOnPageChange(handler: () => void) {
  const location = useLocation();
  useEffect(() => handler(), [location.pathname]);
}

export function useFetchCsrfToken() {
  useEffect(() => {
    async function fetchCsrf() {
      const res = await getCsrfToken();
      if (res.err) {
        return;
      }

      setCsrfTokenCookie(res.data.csrftoken);
    }

    fetchCsrf();
  }, []);
}

export function useIsEduPage(): boolean {
  const location = useLocation();
  const [eduPage, setEduPage] = useState(false);

  useEffect(() => {
    setEduPage(isEduPage(location.pathname));
  }, [location.pathname]);

  return eduPage;
}
