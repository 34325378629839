import React, { FunctionComponent, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import styles from './NavDropdown.module.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  menuPlacement?: 'bottom-start' | 'bottom-end';
}>;

const NavDropdown = (props: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const popperObj = usePopper(referenceElement, popperElement, {
    placement: props.menuPlacement || 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  // for accessibility
  function onToggleClick() {
    if (!isOpen) {
      setIsOpen(true);
    }
  }

  function onEnterDropdown() {
    setIsOpen(true);
  }

  function onLeaveDropdown() {
    setIsOpen(false);
  }

  useEffect(() => {
    // ReactPopper only computes the position of the dropdown on first render.
    // Triggering position recompute when popper is opened.
    if (isOpen && popperObj.update) {
      popperObj.update();
    }
  }, [isOpen, popperObj.update]);

  return (
    <div className={props.className} onMouseEnter={onEnterDropdown} onMouseLeave={onLeaveDropdown}>
      {React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) {
          return;
        }

        // toggle
        if (React.isValidElement<NavDropdownToggleProps>(child) && child.type === NavDropdownToggle) {
          return (
            <button
              {...child.props}
              ref={setReferenceElement}
              className={classNames(styles.navLinkBtn, { [styles.btnActive]: isOpen })}
              onClick={onToggleClick}
              type="button"
              tabIndex={0}
              aria-haspopup
              aria-expanded={isOpen}
            >
              {child.props.children}
            </button>
          );
        }

        // dropdown menu
        if (child.type === NavDropdownMenu) {
          return (
            <div
              {...child.props}
              {...popperObj.attributes.popper}
              ref={setPopperElement}
              style={{
                ...popperObj.styles.popper,
                pointerEvents: isOpen && !!popperElement ? 'auto' : 'none',
                zIndex: 100,
              }}
            >
              <div
                className={styles.dropdownMenu}
                style={{
                  top: isOpen && !!popperElement ? 0 : '5px',
                  opacity: isOpen && !!popperElement ? 1 : 0,
                  pointerEvents: isOpen && !!popperElement ? 'auto' : 'none',
                }}
              >
                {child.props.children}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

type NavDropdownToggleProps = React.PropsWithChildren<{
  variant?: 'navLink';
}>;
export const NavDropdownToggle: FunctionComponent<NavDropdownToggleProps> = () => null;
export const NavDropdownMenu: FunctionComponent<React.PropsWithChildren<{}>> = () => null;

NavDropdown.Toggle = NavDropdownToggle;
NavDropdown.Menu = NavDropdownMenu;
export default NavDropdown;
