import axios from 'axios';
import { appConfig } from '../../commonConfig';
import { getSegmentAnonymousId } from '../services/analyticsService';
import { getCsrfTokenCookie } from '../services/cookieService';
import { LandingPages, ServerErrorCodes, User } from '../types';
import { getAbsoluteUrlMinusQuery } from '../util';
import { parseErr, parseRes, APIResponse, webAPI } from './apiUtil';

type FetchLoginStateRes = {
  logged_in: boolean;
  user?: User;
};

type SignUpReq = {
  email: string;
  username: string;
  password: string;
  isEdu: boolean;
  landingPageVisited?: LandingPages;
  marketingEmailsAccepted: boolean;
};
type SignUpRes = {
  user: User;
  next_url: string;
};
type SignUpErr = {
  result:
    | ServerErrorCodes.GeneralErr
    | ServerErrorCodes.DuplicateEmail
    | ServerErrorCodes.DuplicateUsername
    | ServerErrorCodes.InvalidEmail;
};

type LoginReq = {
  emailOrUsername: string;
  password: string;
};
type LoginErr = {
  result: ServerErrorCodes.GeneralErr | ServerErrorCodes.InvalidLogin;
};

type ForgotPasswordReq = {
  email: string;
};

type ResetPasswordReq = {
  newPassword: string;
  newPasswordConfirm: string;
  userIdB64: string;
};

function getFormDataAxiosInstance() {
  return axios.create({
    baseURL: appConfig.webAppBaseUrl,
    headers: {
      'content-type': 'multipart/form-data',
    },
    withCredentials: true,
  });
}

export function fetchLoginState(): APIResponse<FetchLoginStateRes> {
  return webAPI.get('/user/login-state/').then(parseRes).catch(parseErr);
}

export function signUp(data: SignUpReq): APIResponse<SignUpRes, SignUpErr> {
  return webAPI
    .post('/user/register/', {
      ...data,
      segmentDistinctId: getSegmentAnonymousId(),
      pageAbsoluteUrl: getAbsoluteUrlMinusQuery(),
    })
    .then(parseRes)
    .catch(parseErr);
}

export function login(data: LoginReq): APIResponse<{}, LoginErr> {
  return webAPI
    .post('/user/login/', {
      ...data,
      segmentDistinctId: getSegmentAnonymousId(),
    })
    .then(parseRes)
    .catch(parseErr);
}

// Forgot password is handled by an internal Django view
// this posts form data to that handler
export function forgotPassword(data: ForgotPasswordReq): APIResponse {
  const axiosInstance = getFormDataAxiosInstance();

  const formData = new FormData();
  formData.append('csrfmiddlewaretoken', getCsrfTokenCookie() || '');
  formData.append('email', data.email);

  return axiosInstance.post('/forgot-password/', formData).then(parseRes).catch(parseErr);
}

// Reset password is handled by an internal Django view
// this posts form data to that handler, and requires the proper session id cookie be sent along with it
export function resetPassword(data: ResetPasswordReq): APIResponse {
  const axiosInstance = getFormDataAxiosInstance();

  const formData = new FormData();
  formData.append('csrfmiddlewaretoken', getCsrfTokenCookie() || '');
  formData.append('new_password1', data.newPassword);
  formData.append('new_password2', data.newPasswordConfirm);

  // Post to Django password reset form handler
  return axiosInstance.post(`/reset/${data.userIdB64}/set-password/`, formData).then(parseRes).catch(parseErr);
}
