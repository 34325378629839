import React from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';

export type Props = React.ComponentProps<'button'> & {
  color: 'primary' | 'secondary' | 'transparent' | 'highlight';
  padding?: 'default' | 'light';
  loading?: boolean;
  centered?: boolean;
};

const defaultProps: Partial<Props> = {
  padding: 'default',
};

const Button = ({ className, color, loading, centered, padding, children, ...otherProps }: Props) => {
  const _className = classnames(className, styles.btn, {
    [styles.primary]: color === 'primary',
    [styles.secondary]: color === 'secondary',
    [styles.transparent]: color === 'transparent',
    [styles.highlight]: color === 'highlight',
    [styles.withIcon]: loading,
    [styles.centered]: centered,
    [styles.paddingLight]: padding === 'light',
  });

  return (
    <button className={_className} {...otherProps}>
      {loading ? (
        <div className={styles.contentContainer}>
          <div className={styles.loadingSpinner} />
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
