import React, { FunctionComponent } from 'react';

import styles from './NavMenuItem.module.scss';
import Link from '../Link';

type NavMenuItemProps = {
  title: string;
  desc: string;
  href: string;
  icon?: FunctionComponent<React.SVGAttributes<SVGElement>>;
  useRouter?: boolean;
  target?: string;
};

export const NavMenuItem = ({ title, desc, href, icon, target, useRouter = true }: NavMenuItemProps) => (
  <Link variant="dark" useRouter={useRouter} href={href} target={target}>
    <div className={styles.navMenuLinkContent}>
      {!!icon && icon({ height: '2.4rem', className: styles.navMenuIcon })}
      <div>
        <div className={styles.navMenuTitle}>{title}</div>
        <p className={styles.navMenuDesc}>{desc}</p>
      </div>
    </div>
  </Link>
);
