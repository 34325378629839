import * as Sentry from '@sentry/react';

import { appConfig } from '../../commonConfig';
import { updateWebAPIAuthHeader } from '../apiClients/apiUtil';
import { fetchLoginState } from '../apiClients/userClient';
import defaultUserIcon from '../media/default-user-icon.png';
import { identifyUser } from './analyticsService';
import { setIntercomSettings } from './intercomService';

export function getFullUserIconUrl(relativeUrl?: string) {
  if (!relativeUrl) {
    return defaultUserIcon;
  }

  return `${appConfig.userMediaBaseUrl}/${relativeUrl}`;
}

export async function getUserLoginState() {
  const res = await fetchLoginState();
  if (res.err) {
    updateWebAPIAuthHeader();
    Sentry.captureMessage('Could not fetch user login state', { level: Sentry.Severity.Warning });
    return res;
  }

  // Update 3rd party and API settings anytime a logged in user is successfully fetched
  if (res.data.logged_in && res.data.user) {
    identifyUser(res.data.user);

    updateWebAPIAuthHeader(res.data.user.username, res.data.user.api_key);
  } else {
    updateWebAPIAuthHeader();
  }

  return res;
}
