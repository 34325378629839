import React from 'react';

import ArrowDown from './ArrowDown.svg';
import ArrowRight from './ArrowRight.svg';
import CaretDown from './CaretDown.svg';
import Check from './Check.svg';
import CheckCircle from './CheckCircle.svg';
import ExclamationCircle from './ExclamationCircle.svg';
import Info from './Info.svg';
import QuestionCircle from './QuestionCircle.svg';
import Search from './Search.svg';
import Warning from './Warning.svg';

// NOTE: The svgs for icons should have their fill and stroke colors replaced with "currentColor" in most cases,
// so it can accept the color from a css class
const icons = {
  arrowDown: ArrowDown,
  arrowRight: ArrowRight,
  caretDown: CaretDown,
  check: Check,
  checkCircle: CheckCircle,
  exclamationCircle: ExclamationCircle,
  info: Info,
  questionCircle: QuestionCircle,
  search: Search,
  warning: Warning,
};

const defaultSVGProps = {
  fill: 'currentColor',
  stroke: 'currentColor',
  height: '3rem',
};

export type IconNames = keyof typeof icons;
type Props = React.ComponentProps<'svg'> & {
  name: IconNames;
};

const Icon = ({ name, ...otherProps }: Props) => {
  const iconEl = icons[name];
  if (!iconEl) {
    return null;
  }

  return iconEl({ ...defaultSVGProps, ...otherProps });
};

export default Icon;
