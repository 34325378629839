import React from 'react';
import classNames from 'classnames';

import utilStyles from '../styles/util.module.scss';

type Props = React.PropsWithChildren<{
  el: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
  variant?:
    | 'paragraphSm'
    | 'paragraphMd'
    | 'paragraphLg'
    | 'heading3xs'
    | 'heading2xs'
    | 'headingXs'
    | 'headingSm'
    | 'headingMd'
    | 'headingLg'
    | 'headingXl';
  className?: string;
  fontWeight?: 'bold' | 'semiBold' | 'thin' | 'normal';
  align?: 'center' | 'right';
  noMargin?: boolean;
}>;

const defaultProps: Partial<Props> = {
  variant: 'paragraphMd',
};

const Text = ({ variant, className, children, ...otherProps }: Props) => (
  <otherProps.el
    className={classNames(className, utilStyles[variant || ''], utilStyles[otherProps.fontWeight || ''], {
      [utilStyles.textCenter]: otherProps.align === 'center',
      [utilStyles.textRight]: otherProps.align === 'right',
      [utilStyles.margin0]: !!otherProps.noMargin,
    })}
  >
    {children}
  </otherProps.el>
);

Text.defaultProps = defaultProps;

export default Text;
