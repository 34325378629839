import { defaultConfigs, PublicConfig, setConfig } from '../commonConfig';
import { loadConstantsFromWindow } from './appConstants';

// Load config set on server from window
const appConfig: PublicConfig = window.__GC_CONFIG__ || defaultConfigs.development;

// Cache appConfig val in the commonConfig module so it's accessed from the same place on client and server
setConfig(appConfig);

// Dynamic webpack public path. Should be set before any static file imports
__webpack_public_path__ = appConfig.publicPath;

// So constants are available for any other code to use
loadConstantsFromWindow();
