import React from 'react';
import FormField, { FieldProps } from './FormField';

export type Props = React.ComponentProps<'input'> & Omit<FieldProps<'input'>, 'element' | 'elementProps'>;

// Simple wrapper around FormField for easier use
const Input = React.forwardRef<any, Props>(
  ({ fullWidth, iconLeft, iconRight, error, label, variant, ...otherProps }: Props, ref) => {
    return (
      <FormField
        ref={ref}
        element="input"
        elementProps={otherProps}
        fullWidth={fullWidth}
        iconLeft={iconLeft}
        iconRight={iconRight}
        error={error}
        label={label}
        variant={variant}
      />
    );
  }
);

export default Input;
