import React from 'react';
import classNames from 'classnames';

import GooseChaseIconDark from '../../media/goosechase-icon-dark.svg';
import RemoteIcon from '../../media/richIcons/remote.svg';
import OnboardingIcon from '../../media/richIcons/onboarding.svg';
import TourismIcon from '../../media/richIcons/tourism.svg';
import K12Icon from '../../media/richIcons/k12.svg';
import RecreationIcon from '../../media/richIcons/recreation.svg';
import CampusOrientationIcon from '../../media/richIcons/campusOrientation.svg';

import styles from './Navbar.module.scss';
import Container from '../layout/Container';
import Link from '../Link';
import routes from '../../routes';
import NavDropdown from './NavDropdown';
import { NavMenuItem } from './NavMenuItem';
import Icon from '../icons/Icon';
import Flex from '../layout/Flex';
import NavHamburger from './NavHamburger';
import { appConfig } from '../../../commonConfig';
import { useIsEduPage } from '../../hooks/utilHooks';
import { useMediaQuery } from '@material-ui/core';
import { ResourcesNav } from './ResourcesNav';

type Props = {
  mobileMenuOpen: boolean;
  onMobileMenuToggle(): void;
};

const Navbar = (props: Props) => {
  // js media query is used to not render the same links in the DOM twice (for SEO)
  const isDesktop = useMediaQuery(`(min-width: ${styles.mobileNavBreakpointStr})`);
  const isEdu = useIsEduPage();

  const renderUserSection = (mobileNav = false) => {
    const linkItemClass = mobileNav ? styles.mobileNavLink : styles.linkItem;
    return (
      <>
        {mobileNav && <Divider />}
        <Link
          variant="dark"
          className={linkItemClass}
          href={isEdu ? `${appConfig.studioUrl}/sign-up?type=edu` : `${appConfig.studioUrl}/experiences`}
        >
          My Experiences
        </Link>
      </>
    );
  };

  function renderNavMenuItem(
    name:
      | 'virtual'
      | 'onboarding'
      | 'k12'
      | 'recreation'
      | 'campus'
      | 'organizing'
      | 'playing'
      | 'whatIsIxp'
      | 'sxlPricing'
      | 'subscriptions'
      | 'k12Pricing'
      | 'tourism'
  ) {
    switch (name) {
      case 'virtual':
        return (
          <NavMenuItem
            icon={RemoteIcon}
            title="Team building"
            desc="Bring your virtual, hybrid or in-person team together."
            href={routes.VIRTUAL_TEAMS_USES.path}
          />
        );
      case 'onboarding':
        return (
          <NavMenuItem
            icon={OnboardingIcon}
            title="Onboarding"
            desc="Create an experience that gets new hires prepped and pumped."
            href={routes.ONBOARDING_USES.path}
          />
        );
      case 'k12':
        return (
          <NavMenuItem
            icon={K12Icon}
            title="K-12 education"
            desc="Boost learning by making the curriculum fun and interactive."
            href={routes.K12_EDUCATION_USES.path}
          />
        );
      case 'recreation':
        return (
          <NavMenuItem
            icon={RecreationIcon}
            title="Recreation"
            desc="Activate your community to have fun and do good together!"
            href={routes.RECREATION_USES.path}
          />
        );
      case 'campus':
        return (
          <NavMenuItem
            icon={CampusOrientationIcon}
            title="Campus orientation"
            desc="Plan events that bring your campus to life"
            href={routes.CAMPUS_ORIENTATION_USES.path}
          />
        );
      case 'organizing':
        return (
          <NavMenuItem
            title="Organizing"
            desc="Learn how to create your first Experience."
            href={routes.HIW_ORGANIZING.path}
          />
        );
      case 'playing':
        return (
          <NavMenuItem title="Playing" desc="Ready to play? Learn how to get started." href={routes.HIW_PLAYING.path} />
        );
      case 'whatIsIxp':
        return (
          <NavMenuItem
            title="What is an IXP?"
            desc="Find out what an interactive experience platform can do."
            href={routes.WHAT_IS_AN_IXP.path}
          />
        );
      case 'sxlPricing':
        return (
          <NavMenuItem
            title="Single Experience"
            desc="One-off events for communities of all sizes"
            href={routes.PRICING.path}
          />
        );
      case 'subscriptions':
        return (
          <NavMenuItem
            title="Subscriptions"
            desc="Unlimited access for your whole organization"
            href={routes.WHY_BUY_A_SUBSCRIPTION.path}
          />
        );
      case 'k12Pricing':
        return (
          <NavMenuItem
            title="K-12 Educator"
            desc="For K-12 teachers, schools & districts"
            href={routes.EDU_PRICING.path}
          />
        );
      case 'tourism':
        return (
          <NavMenuItem
            icon={TourismIcon}
            title="Tourism"
            desc="Customize interactive experiences that highlight the best of your attraction."
            href={routes.TOURISM_USES.path}
          />
        );
      default:
        return null;
    }
  }

  return (
    <nav className={styles.container}>
      <Container maxWidth="lg">
        <div className={styles.navbar}>
          <div className={styles.mainLinkGroup}>
            <Link className={styles.logoLink} href={routes.HOME.path} useRouter aria-label="Goosechase home">
              <GooseChaseIconDark className={styles.logo} />
            </Link>
            {isDesktop && (
              <div className={styles.horizLinkGroup}>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    Uses <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <Flex>
                      <div className={styles.navUsesColumn}>
                        {renderNavMenuItem('virtual')}
                        {renderNavMenuItem('onboarding')}
                        {renderNavMenuItem('k12')}
                      </div>
                      <div className={styles.navUsesColumn}>
                        {renderNavMenuItem('recreation')}
                        {renderNavMenuItem('campus')}
                        {renderNavMenuItem('tourism')}
                      </div>
                    </Flex>
                  </NavDropdown.Menu>
                </NavDropdown>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    How it works <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <div className={styles.singleColumnMenuContent}>
                      {renderNavMenuItem('whatIsIxp')}
                      {renderNavMenuItem('organizing')}
                      {renderNavMenuItem('playing')}
                    </div>
                  </NavDropdown.Menu>
                </NavDropdown>
                <NavDropdown className={styles.linkItem}>
                  <NavDropdown.Toggle>
                    Pricing <Icon name="arrowDown" height="2.4rem" css={{ verticalAlign: 'bottom' }} />
                  </NavDropdown.Toggle>
                  <NavDropdown.Menu>
                    <div className={styles.singleColumnMenuContent}>
                      {renderNavMenuItem('subscriptions')}
                      {renderNavMenuItem('sxlPricing')}
                      {renderNavMenuItem('k12Pricing')}
                    </div>
                  </NavDropdown.Menu>
                </NavDropdown>
                <Link variant="dark" className={styles.linkItem} href={routes.ABOUT.path} useRouter>
                  About us
                </Link>
                <ResourcesNav isDesktop={isDesktop} />
              </div>
            )}
          </div>
          {isDesktop && <div className={styles.userLinkGroup}>{renderUserSection()}</div>}
          <NavHamburger mobileMenuOpen={props.mobileMenuOpen} onMobileMenuToggle={props.onMobileMenuToggle} />
        </div>
      </Container>

      {!isDesktop && (
        <div className={classNames({ [styles.hidden]: !props.mobileMenuOpen })}>
          <Container maxWidth="lg" className={styles.mobileMenu}>
            <div className={styles.mobileGroupTitle}>Uses</div>
            {renderNavMenuItem('virtual')}
            {renderNavMenuItem('onboarding')}
            {renderNavMenuItem('k12')}
            {renderNavMenuItem('recreation')}
            {renderNavMenuItem('campus')}
            {renderNavMenuItem('tourism')}

            <Divider />

            <div className={styles.mobileGroupTitle}>How it works</div>
            {renderNavMenuItem('whatIsIxp')}
            {renderNavMenuItem('organizing')}
            {renderNavMenuItem('playing')}

            <Divider />

            <div className={styles.mobileGroupTitle}>Pricing</div>
            {renderNavMenuItem('subscriptions')}
            {renderNavMenuItem('sxlPricing')}
            {renderNavMenuItem('k12Pricing')}

            <Divider />
            <Link variant="dark" className={styles.mobileNavLink} href={routes.ABOUT.path} useRouter>
              About us
            </Link>
            <Divider />
            <ResourcesNav isDesktop={isDesktop} />
            {renderUserSection(true)}
          </Container>
        </div>
      )}
    </nav>
  );
};

const Divider = ({ noMargin }: { noMargin?: boolean }) => (
  <hr className={classNames({ [styles.divider]: !noMargin, [styles.dividerNoMargin]: noMargin })} />
);

export default Navbar;
