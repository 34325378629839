import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './FormField.module.scss';
import Icon from '../icons/Icon';

export type FieldProps<T extends keyof JSX.IntrinsicElements> = React.PropsWithChildren<{
  element: T;
  elementProps: React.ComponentProps<T>;
  fullWidth?: boolean;
  variant?: 'default' | 'sm';
  error?: string;
  className?: string;
  label?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
}>;
type Props = FieldProps<'input'> | FieldProps<'textarea'> | FieldProps<'select'>;

const FormField = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const isCheckbox = props.element === 'input' && props.elementProps.type === 'checkbox';
  const elClassName = classnames(props.elementProps.className, {
    [styles.fieldEl]: props.variant === 'default' || !props.variant,
    [styles.fieldElSm]: props.variant === 'sm',
    [styles.fieldError]: !!props.error,
    [styles.fullWidth]: props.fullWidth,
    [styles.extraSelectStyles]: props.element === 'select',
    [styles.extraTextAreaStyles]: props.element === 'textarea',
  });

  return (
    <div
      className={classnames(props.className, {
        [styles.textBoxRoot]: !isCheckbox,
        [styles.checkboxRoot]: isCheckbox,
        [styles.checkboxDisabled]: isCheckbox && props.elementProps.disabled,
        [styles.fullWidth]: props.fullWidth,
      })}
      ref={ref}
    >
      {props.label && props.elementProps.id && (
        <label htmlFor={props.elementProps.id} className={styles.label}>
          {props.element === 'input' && isCheckbox ? (
            <>
              <span className={styles.checkboxInputContainer}>
                <input {...props.elementProps} className={elClassName} />
                <span
                  className={classnames(styles.visibleCheckbox, {
                    [styles.checked]: props.elementProps.checked,
                    [styles.visibleCheckboxErr]: !!props.error,
                  })}
                >
                  {props.elementProps.checked && <Icon className={styles.checkIcon} name="check" height="1.2rem" />}
                </span>
              </span>
              <span className={styles.labelText}>{props.label}</span>
            </>
          ) : (
            props.label
          )}
        </label>
      )}
      {props.element === 'input' && !isCheckbox && (
        <div
          className={classnames(styles.textContainer, {
            [styles.hasLeftIcon]: props.iconLeft,
            [styles.hasRightIcon]: props.iconRight,
          })}
        >
          {props.iconLeft && <div className={styles.iconLeft}>{props.iconLeft}</div>}
          <input {...props.elementProps} className={elClassName} />
          {props.iconRight && <div className={styles.iconRight}>{props.iconRight}</div>}
        </div>
      )}
      {props.element === 'textarea' && <textarea {...props.elementProps} className={elClassName} />}
      {props.element === 'select' && (
        <div className={styles.selectContainer}>
          <select {...props.elementProps} className={elClassName}>
            {props.children}
          </select>
          <Icon name="arrowDown" className={styles.selectArrow} />
        </div>
      )}

      {props.error && <div className={styles.errorText}>{props.error}</div>}
    </div>
  );
});

export default FormField;
