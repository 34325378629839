import { useState } from 'react';
import { ValidationResult, ValidationRule } from '../types';

export const PASSWORD_MIN_LEN = 6;

export function isValidEmail(email: string) {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

// Loops through list of rules, returns the first error that fails, or returns no error if all passed
export function validate(rules: ValidationRule[]): ValidationResult {
  for (let i = 0; i < rules.length; i++) {
    if (rules[i].hasErr) {
      return rules[i];
    }
  }

  return { hasErr: false };
}

export function hasErr(validationResults: ValidationResult[]): boolean {
  for (let i = 0; i < validationResults.length; i++) {
    if (validationResults[i].hasErr) {
      return true;
    }
  }

  return false;
}

// Common validators
export function validateEmail(email: string): ValidationResult {
  return validate([
    { hasErr: !email, errMsg: 'Whoops! Email is required' },
    { hasErr: !isValidEmail(email), errMsg: 'Whoops! That email is invalid' },
  ]);
}

export function validateNewPassword(password: string): ValidationResult {
  return validate([
    { hasErr: !password, errMsg: 'Whoops! Password is required' },
    { hasErr: password.length < PASSWORD_MIN_LEN, errMsg: 'Whoops! create a strong password' },
  ]);
}
