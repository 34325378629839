import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { appConfig } from '../../commonConfig';

interface SuccessRes<Data> {
  data: Data;
  err: null;
}

export type APIResponse<ResData = any, ErrData = any> = Promise<SuccessRes<ResData> | { err: AxiosError<ErrData> }>;

export const webAPI: AxiosInstance = axios.create();

// NOTE: to make cross origin requests to the API in development, you'll need to workaround the browser blocking
// cors requests from 'localhost' in some way
export async function initWebAPIConfig() {
  webAPI.defaults.baseURL = appConfig.apiBaseUrl;
  webAPI.defaults.withCredentials = true; // allows us to send cookies with the request (needed to send django session and csrf cookie)
  webAPI.defaults.headers.common = {
    'Content-Type': 'application/json',
    Authorization: '', // To be set later when user data is fetched
  };
  webAPI.interceptors.request.use((config) => ({
    ...config,
    params: {
      ...config.params,
      format: 'json',
    },
  }));
}

// Set API Authorization header in format required by TastyPie, or set to blank if empty params provided (user logged out)
export function updateWebAPIAuthHeader(username?: string, apiKey?: string) {
  webAPI.defaults.headers.common.Authorization =
    username && apiKey ? `ApiKey ${encodeURIComponent(username)}:${apiKey}` : '';
}

export function parseRes<T = any>(res: AxiosResponse<T>): SuccessRes<T> {
  return { data: res.data, err: null };
}

export function parseErr<T = any>(err: AxiosError<T>): { err: AxiosError<T> } {
  // log api request errors to sentry, if >=500 or no response received
  if (!err.response || err.response.status >= 500) {
    Sentry.captureException(err, {
      extra: {
        errResData: err.response?.data,
      },
      tags: {
        gc_api_req_err: true,
      },
    });
  }

  return { err };
}
