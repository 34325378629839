import React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import { Theme, makeStyles } from '@material-ui/core';

type Props = React.ComponentProps<typeof MUITooltip> & {
  variant?: 'default' | 'error';
};

const defaultProps: Partial<Props> = {
  variant: 'default',
};

const Tooltip = (props: Props) => {
  const classes = useTooltipStyles(props);

  return (
    <MUITooltip {...props} classes={classes}>
      {props.children}
    </MUITooltip>
  );
};

const useTooltipStyles = makeStyles<Theme, Props>({
  tooltip: {
    fontSize: '1.3rem',
    backgroundColor: (props) => (props.variant === 'error' ? '#fae0da' : 'rgba(0, 0, 0, 0.9)'),
    color: (props) => (props.variant === 'error' ? '#cd4725' : '#fff'),
    border: (props) => (props.variant === 'error' ? '1px solid #cd4725' : undefined),
  },
  arrow: (props) =>
    props.variant === 'error'
      ? {
          color: '#fae0da',
          '&:before': {
            border: '1px solid #cd4725',
          },
        }
      : {
          color: 'rgba(0, 0, 0, 0.9)',
        },
});

Tooltip.defaultProps = defaultProps;

export default Tooltip;
