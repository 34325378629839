import React, { useState } from 'react';

import styles from './NewsletterForm.module.scss';
import { subscribeToNewsletter } from '../apiClients/miscApiClient';
import { ReqStatus } from '../types';
import Button from './Button';
import Input from './form/Input';
import Tooltip from './Tooltip';
import { isValidEmail } from '../services/validationService';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [reqStatus, setReqStatus] = useState<ReqStatus>();
  const [tooltipMsg, setTooltipMsg] = useState('');

  async function onSubmitForm(e: React.FormEvent) {
    e.preventDefault();

    if (!email || !isValidEmail(email)) {
      setTooltipMsg('Please enter a valid email address');
      setReqStatus('err');
      return;
    }

    setReqStatus('pending');
    setTooltipMsg('');

    const res = await subscribeToNewsletter({ email });
    if (res.err) {
      setTooltipMsg('Something went wrong, please try again.');
      setReqStatus('err');
      return;
    }

    setReqStatus('success');
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    setTooltipMsg('');
  }

  if (reqStatus === 'success') {
    return (
      <div className={styles.successMsg}>
        Thank you for signing up!
        <br />
        You’ll hear from us soon.
      </div>
    );
  }

  return (
    <div>
      <div className={styles.title}>Get our email newsletter</div>
      <form noValidate onSubmit={onSubmitForm}>
        <Tooltip
          variant="error"
          title={tooltipMsg}
          arrow
          placement="top"
          open={!!tooltipMsg}
          TransitionProps={{ exit: false }}
        >
          <Input
            className={styles.input}
            type="text"
            variant="sm"
            placeholder="Enter your email"
            aria-label="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            disabled={reqStatus === 'pending'}
          />
        </Tooltip>
        <Button type="submit" className={styles.btn} disabled={reqStatus === 'pending'}>
          Subscribe
        </Button>
      </form>
    </div>
  );
};

export default NewsletterForm;
