import React, { useState } from 'react';
import { keyframes } from '@emotion/react';
import { useTheme } from '@material-ui/core';

import routes from '../routes';
import {
  haveCookieConsentResponse,
  setCookieConsentResponseReceived,
  updateCookieConsent,
} from '../services/cookieService';
import { useHistory } from 'react-router';
import colors from '../styles/_colors.module.scss';

const cookieFadeInKeyframes = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const CookieConsentPopup = () => {
  const theme = useTheme();
  const history = useHistory();

  const [hidePopup, setHidePopup] = useState(false);

  const onAcceptAllCookiesClick = () => {
    updateCookieConsent({ analyticsCookiesAccepted: true, adCookiesAccepted: true });
    setHidePopup(true);
  };

  const onCookieSettingsClick = () => {
    setCookieConsentResponseReceived();
    setHidePopup(true);
    history.push(routes.COOKIE_POLICY.path);
  };

  if (hidePopup || haveCookieConsentResponse()) {
    return null;
  }

  return (
    <div
      css={{
        position: 'fixed',
        width: 'unset',
        left: '16px',
        right: '16px',
        bottom: '16px',
        zIndex: 2147483647, // max 32-bit int, so it displays on top of Intercom
        fontFamily: 'proxima-nova, sans-serif',
        borderRadius: '8px',
        boxShadow: '0 0 8px 1px rgb(0 0 0 / 15%)',
        padding: '20px',
        backgroundColor: '#fff',
        color: 'rgb(29, 3, 60)',
        fontSize: '14px',
        lineHeight: '1.43',
        boxSizing: 'border-box',
        animation: `${cookieFadeInKeyframes} 0.4s ease-in-out forwards`,
        [theme.breakpoints.up('sm')]: {
          width: '393px',
          left: '30px',
          right: 'unset',
          bottom: '30px',
        },
      }}
    >
      <div
        css={{
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: '10px',
        }}
      >
        Cookies 🍪
      </div>
      <p>
        We use cookies to give you a better experience. You can change your settings anytime in our{' '}
        <button
          type="button"
          onClick={onCookieSettingsClick}
          css={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            fontWeight: 600,
            padding: 0,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            textDecoration: 'underline',
            color: colors.blue,
          }}
        >
          Cookie Settings
        </button>
        .
      </p>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '18px',
        }}
      >
        <button
          type="button"
          onClick={onCookieSettingsClick}
          css={{
            fontSize: '16px',
            width: '41%',
            height: '43px',
            minWidth: '96px',
            fontFamily: 'inherit',
            marginRight: '12px',
            padding: '10px',
            borderRadius: '24px',
            border: 'solid 1px rgb(29, 3, 60)',
            backgroundColor: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            boxSizing: 'border-box',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          More Info
        </button>
        <button
          type="button"
          onClick={onAcceptAllCookiesClick}
          css={{
            flex: 1,
            fontSize: '16px',
            fontFamily: 'inherit',
            height: '43px',
            padding: '10px',
            borderRadius: '21px',
            backgroundColor: 'rgb(29, 3, 60)',
            border: 'solid 1px rgb(29, 3, 60)',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            color: '#fff',
          }}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsentPopup;
