import React from 'react';
import classNames from 'classnames';

import styles from './NavHamburger.module.scss';

type Props = {
  mobileMenuOpen: boolean;
  onMobileMenuToggle(): void;
};

const NavHamburger = (props: Props) => (
  <button
    className={classNames(styles.hamburger, { [styles.hamburgerClose]: props.mobileMenuOpen })}
    onClick={props.onMobileMenuToggle}
    aria-label="Toggle Menu"
    aria-expanded={props.mobileMenuOpen}
  >
    <div className={styles.hamburgerTopLine} />
    <div className={styles.hamburgerMidLine} />
    <div className={styles.hamburgerBotLine} />
  </button>
);

export default NavHamburger;
