import React from 'react';
import { Theme } from '@material-ui/core';
import loadable from '@loadable/component';

import Page, { Section } from '../components/Page';
import Flex from '../components/layout/Flex';
import Box from '../components/layout/Box';
import Text from '../components/Text';
import Link from '../components/Link';
import routes from '../routes';

const Illustration404 = loadable(() => import('../media/404.svg'));

const Error404 = () => (
  <Page title="Page Does Not Exist | Goosechase" hideFooter>
    <Section padding="sm">
      <Flex justifyContent="center">
        <Box textAlign="center">
          <Illustration404 width="75.3rem" />
          <Text
            el="h1"
            variant="headingMd"
            css={(theme: Theme) => ({
              marginTop: '3.2rem',
              [theme.breakpoints.up('sm')]: { marginTop: '4.8rem' },
            })}
          >
            Oops! Page not found
          </Text>
          <Text el="p" variant="paragraphMd" css={{ maxWidth: '52.4rem', margin: '0 auto 2.6rem auto' }}>
            Unless you were looking for a page with a gaggle of illustrated geese shaped into the numbers 404. If that’s
            the case, it definitely exists, and congratulations, you’ve found it!
          </Text>
          <Link href={routes.HOME.path} variant="underline" withArrow useRouter>
            Head to our homepage
          </Link>
        </Box>
      </Flex>
    </Section>
  </Page>
);

export default Error404;
