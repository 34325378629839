import appConstants from '../appConstants';
import { isServer } from '../util';
import { trackEvent } from './analyticsService';

type CookieNames =
  | 'analytics_cookies_accepted'
  | 'ads_cookies_accepted'
  | 'cookie_consent_response_received'
  | 'csrftoken';

export function isAnalyticsCookiesAccepted() {
  return getCookie('analytics_cookies_accepted') === 'true';
}

export function isAdsCookiesAccepted() {
  return getCookie('ads_cookies_accepted') === 'true';
}

export function haveCookieConsentResponse() {
  return getCookie('cookie_consent_response_received') === 'true';
}

export function getCsrfTokenCookie() {
  return getCookie('csrftoken');
}

export function updateCookieConsent(consentData: {
  analyticsCookiesAccepted?: boolean;
  adCookiesAccepted?: boolean;
}): void {
  if (consentData.adCookiesAccepted !== undefined) {
    setCookieInBrowser(
      'ads_cookies_accepted',
      consentData.adCookiesAccepted ? 'true' : 'false',
      getCookieConsentExpiryDate()
    );
  }

  setCookieConsentResponseReceived();
}

export function setCookieConsentResponseReceived() {
  setCookieInBrowser('cookie_consent_response_received', 'true', getCookieConsentExpiryDate());
}

// Token from django csrf middleware
export function setCsrfTokenCookie(token: string) {
  // Expire 1 year from now (same as django csrf middleware)
  const expiry = new Date();
  expiry.setMonth(expiry.getMonth() + 12);

  setCookieInBrowser('csrftoken', token, expiry);
}

function getCookie(name: CookieNames): string | null {
  let cookieStr = '';
  if (isServer()) {
    cookieStr = appConstants?.SERVER_COOKIE_STRING;
  } else {
    cookieStr = document.cookie;
  }

  const nameLenPlus = name.length + 1;
  return (
    cookieStr
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}

function getCookieConsentExpiryDate(): Date {
  const expiry = new Date();

  // Expire 6 months from now
  expiry.setMonth(expiry.getMonth() + 6);
  return expiry;
}

function setCookieInBrowser(name: CookieNames, val: string, expiryDate: Date) {
  if (isServer()) {
    return;
  }

  document.cookie = `${name}=${val}; expires=${expiryDate.toUTCString()}; path=/`;
}
